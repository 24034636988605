/* Colors */
$yellow: #fedb00;
$black: #121211;
$white: #fff;
$grey: #e6e7e8;
$greyLighter: #f8f9f9;
$grey-30: #cccccc;
$grey-40: #d9d9d9;
$grey-50: #a1a1a1;

$lightBlack: #383836;
$black-100: #000000;
$black-70: #38393f;
$blackFont: #383937;

/* Media */
$mobile: 640px;
$tablet-small: 768px;
$tablet: 1080px;
$desktop: 1081px;
$desktop-large: 1300px;
$desktop-huge: 1921px;

/* Fonts */
$mainFont: 'Greycliff CF';
//$mainFont: Acumin Pro;

//container widths
$md: 846px;
$lg: 1280px;
