@import "styles/variables.scss";

.footer {
  @media (max-width: $tablet) {
    padding: 48px 16px;
    background: $black;
  }

  @media (min-width: $desktop) {
    padding: 80px 0;
    background: $black;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
  }

  &__top {
    @media (max-width: $tablet) {
      display: flex;
      flex-direction: column;
    }

    @media (min-width: $desktop) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 86px;
    }
  }

  &__top-left {
    @media (max-width: $tablet) {
      display: flex;
      justify-content: space-between;
    }
    
    @media (min-width: $desktop) {
      display: flex;
    }

    & a {
      align-self: flex-start;
    }
  }

  &__top-left-wrap {
    @media (max-width: $tablet) {
      display: flex;
      flex-direction: column;
      margin-bottom: 56px;
    }
    
    @media (min-width: $desktop) {
      display: flex;
      flex-direction: column;
      margin-right: 17px;
    }
  }

  &__top-left-title {
    @media (max-width: $tablet) {

      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: -0.8999999761581421px;
      color: $white;
      margin-bottom: 8px;
    }

    @media (min-width: $desktop) {

      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: -1.100000023841858px;
      color: $white;
    }
  }

  &__top-left-text {
    @media (max-width: $tablet) {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.4000000059604645px;
      color: $white;
    }

    @media (min-width: $desktop) {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: -0.8999999761581421px;
      color: $white;
    }

    & a {
      @media (max-width: $tablet) {
        min-width: 135px;
      }
    }
  }

  &__top-right {
    @media (max-width: $tablet) {
      display: flex;
      justify-content: space-between;
    }
    
    @media (min-width: $desktop) {
      display: flex;
    }

    & a {
      align-self: flex-start;
    }
  }

  &__top-right-wrap {
    @media (max-width: $tablet) {
      display: flex;
      flex-direction: column;
      margin-bottom: 56px;
    }
    
    @media (min-width: $desktop) {
      display: flex;
      flex-direction: column;
    }
  }

  &__top-right-title {
    @media (max-width: $tablet) {

      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: -0.8999999761581421px;
      color: $white;
      margin-bottom: 8px;
    }

    @media (min-width: $desktop) {

      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: -1.100000023841858px;
      color: $white;
    }
  }

  &__top-right-text {
    @media (max-width: $tablet) {

      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.4000000059604645px;
      color: $white;
    }

    @media (min-width: $desktop) {

      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: -0.8999999761581421px;
      color: $white;
    }
  }

  &__bottom {
    @media (max-width: $tablet) {
      border-top: 1px solid $grey;
      padding-top: 31px;
      display: flex;
    }

    @media (min-width: $desktop) {
      border-top: 1px solid $grey;
      padding-top: 40px;
      display: flex;
    }

    & ul {
      margin-right: 180px;

      &:last-child {
        margin-right: 0;
      }

      & li {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }

        & a {

          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.4000000059604645px;
          color: $white;
        }
      }
    }
  }

  &__bottom-link {
    list-style: none;
    @media (max-width: $tablet) {
      display: none;
    }

    @media (min-width: $desktop) {
      display: block;
    }
  }

  &__bottom-social-link-wrap {
    display: flex;
    flex-direction: column;
  }

  &__bottom-social-link-title {
    @media (max-width: $tablet) {

      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.4000000059604645px;
      color: $grey;
      margin-bottom: 8px;
    }

    @media (min-width: $desktop) {
      display: none;
    }
  }

  &__bottom-social-link {
    & li {
      & a {
        display: flex;
        justify-content: space-between;
        min-width: 176px;

        &::after {
          display: block;
          content: '';
          width: 24px;
          height: 24px;
          background-image: url("../../images/svg/arrow-up-right.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
  }
}