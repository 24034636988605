@import "styles/variables.scss";

.footer {
  @media (max-width: $tablet) {
    background-color: $grey;
    display: flex;
    flex-direction: column;
    padding: 16px;
  }

  @media (min-width: $desktop) {
    background-color: $grey;
    display: flex;
    flex-direction: column;
    padding: 26px 0 31px;
  }

  &__top {
    @media (max-width: $tablet) {
      display: flex;
      flex-direction: column;
      margin-bottom: 36px;
    }

    @media (min-width: $desktop) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 24px;
    }

    & ul {
      display: flex;
      list-style: none;

      @media (max-width: $tablet) {
        display: flex;
        flex-direction: column;
      }

      @media (min-width: $desktop) {
        display: flex;
      }

      & li {
        @media (min-width: $desktop) {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 20px;
          margin: 0;
        }

        &:last-child {
          padding-right: 0;

          &::after {
            display: none;
          }
        }

        &::after {
          @media (max-width: $tablet) {
            content: none;
          }

          @media (min-width: $desktop) {
            background: $black;
            position: relative;
            right: -10px;
          }
        }

        & span {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.4000000059604645px;
          color: $black;

          // & span {
          //     @media (max-width: $tablet) {
          //         font-weight: 700;
          //     }
          // }
        }
      }
    }
  }

  &__top-copyright {
    display: flex;

    @media (max-width: $tablet) {
      margin-bottom: 12px;
    }

    & span {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.4000000059604645px;
    }
  }

  &__top-copyright-first {
    margin-right: 8px;

    @media (max-width: $tablet) {
      font-weight: 700 !important;
    }
  }

  &__bottom {
    display: flex;

    @media (max-width: $tablet) {
      flex-direction: column-reverse;
    }

    @media (min-width: $desktop) {
      justify-content: space-between;
    }

    & span {
      max-width: 900px;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.4000000059604645px;
    }

    & ul {
      display: flex;
      list-style: none;

      @media (max-width: $tablet) {
        margin-bottom: 8px;
      }

      @media (min-width: $desktop) {
        align-self: flex-end;
      }

      & li {
        @media (max-width: $tablet) {
          margin-right: 16px;
        }

        @media (min-width: $desktop) {
          margin-right: 24px;
        }

        &:last-child {
          margin-right: 0;
        }

        & a {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.4000000059604645px;
          position: relative;

          &:hover {
            &::after {
              display: block;
              content: "";
              width: 100%;
              height: 2px;
              background: #424242;
              position: absolute;
              bottom: -3px;
              left: 0;
            }
          }
        }
      }
    }
  }
}
