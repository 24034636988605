@import "styles/variables.scss";

.top-header {
  &__pop-up {
    z-index: 6;
    background-color: $white;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    @media (max-width: $tablet) {
      height: auto;
      position: absolute;
      width: 100%;
      top: 0;
      right: 0;
    }

    @media (min-width: $desktop) {
      width: 776px;
      position: fixed;
      top: 1rem;
      right: 50%;
      transform: translate(50%, 0);
    }
  }

  &__close {
    position: relative;

    @media (max-width: $tablet) {
      margin-bottom: 24px;
    }

    @media (min-width: $desktop) {
      margin-bottom: 32px;
    }

    .close-wrapper {
      width: 24px;
      height: 24px;
      top: 8px;
      right: 8px;
      position: absolute;
      cursor: pointer;
    }
  }

  &__pop-up-title {
    font-style: normal;
    font-weight: 700;

    @media (max-width: $tablet) {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.9px;
      margin-bottom: 24px;
      margin-left: 16px;
    }

    @media (min-width: $desktop) {
      line-height: 30px;
      font-size: 30px;
      letter-spacing: -1.2px;
      margin-left: 80px;
      margin-bottom: 32px;
    }
  }

  &__pop-up-top-list {
    display: flex;
    list-style: none;

    @media (min-width: $desktop) {
      margin-left: 80px;
    }

    & li {
      .--active {
        background: $yellow;
      }

      text-transform: uppercase;
      background: $grey;
      cursor: pointer;

      @media (max-width: $tablet) {
        padding: 8px 24px;
      }

      @media (min-width: $desktop) {
        padding: 8px 40px;
      }

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  &__pop-up-yellow-block {
    background: $yellow;
    display: flex;
    flex-direction: column;

    @media (max-width: $tablet) {
      padding: 24px 16px;
    }

    @media (min-width: $desktop) {
      padding: 40px 48px 27px 80px;
    }

    a:hover {
      cursor: pointer;
    }
  }

  &__pop-up-yellow-block-top {
    display: grid;
    // grid-template-columns: 1fr;

    @media (max-width: $tablet) {
      border-bottom: 2px solid #121211;
      margin-bottom: 22px;
      grid-row-gap: 16px;
    }

    @media (min-width: $desktop) {
      padding-bottom: 16px;
      border-bottom: 1px solid #121211;
      margin-bottom: 23px;
      grid-row-gap: 8px;
    }

    & a {
      @media (max-width: $tablet) {
        display: block;
        padding: 0;
        background: none;
        min-height: unset;
        border: none;
        color: black;
        font-weight: 400;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &:first-of-type {
        &::after {
          @media (max-width: $tablet) {
            display: block;
            content: "";
            width: 8px;
            height: 14px;
            background-image: url(../../../images/svg/black-arrow-button.svg);
          }
        }
      }

      &::after {
        display: none;
      }
    }

    &:nth-of-type(2) {
      display: grid;

      @media (max-width: $tablet) {
        grid-template-columns: 1fr;
        grid-row-gap: 18px;
      }

      @media (min-width: $desktop) {
        grid-row-gap: 24px;
        // grid-template-columns: repeat(3, 1fr);
      }
    }

    &:last-of-type {
      border: 0;
    }
  }

  &__pop-up-yellow-block-title {
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    @media (max-width: $tablet) {
      font-size: 16px;
      letter-spacing: -0.1px;
    }

    @media (min-width: $desktop) {
      grid-column-start: 1;
      grid-column-end: 4;
      letter-spacing: -0.4px;
      font-size: 18px;
    }
  }

  &__pop-up-yellow-button-wrap {
    display: grid;
    grid-template-columns: 1fr;

    @media (max-width: $tablet) {
      grid-row-gap: 22px;
    }

    @media (min-width: $desktop) {
      display: flex;
      flex-direction: column;

      a {
        &:first-of-type {
          margin-bottom: 8px;
        }
      }
    }
  }

  &__pop-up-yellow-button-wrap-link {
    @media (max-width: $tablet) {
      display: none !important;
    }
  }

  &__pop-up-yellow-button-wrap-text {
    font-style: normal;
    font-weight: 400;

    @media (max-width: $tablet) {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.001em;
      padding-bottom: 17px;
      border-bottom: 1px solid $white;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    @media (min-width: $desktop) {
      pointer-events: none;
      max-width: 150px;
      line-height: 16px;
      letter-spacing: 0.4px;
      font-size: 12px;
    }

    &::after {
      @media (max-width: $tablet) {
        display: block;
        content: "";
        width: 8px;
        height: 14px;
        background-image: url(../../../images/svg/black-arrow-button.svg);
      }
    }
  }

  &__pop-up-covid {
    display: flex;
    display: none;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    @media (max-width: $tablet) {
      padding: 8px 16px;
      flex-direction: column;
      font-size: 16px;
      letter-spacing: -0.001em;
    }

    @media (min-width: $desktop) {
      padding: 8px 80px;
      font-size: 18px;
      letter-spacing: -0.4px;
    }

    & span {
      font-weight: 700;
      margin-right: 20px;
    }
  }
}
