@import 'styles/variables.scss';

.top-header {
  background: $lightBlack;
  display: flex;
  padding: 8px 18px 13px 8px;
  z-index: 4;
  position: relative;

  @media (min-width: $desktop) {
    padding: 12px 0;
  }

  &__wrap {
    display: flex;
    justify-content: space-between;

    @media (min-width: $desktop) {
      display: flex;
      justify-content: space-between;
      min-height: 32px;
      align-items: center;
    }
  }

  & .container {
    border: 0;
  }

  &__pop-up-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 110vh;
    background-color: rgba(204, 204, 204, 0.5);
  }

  &__left-block {
    display: flex;

    & span {
      color: $white;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin-right: 8px;
      letter-spacing: 0.4000000059604645px;

      @media (min-width: $desktop) {
        color: $grey;
      }
    }
  }

  &__left-block-list {
    display: flex;
    position: relative;

    @media (min-width: $desktop) {
      display: flex;
    }

    & li {
      margin-right: 17px;
      position: relative;
      display: none;

      &:nth-child(1) {
        display: flex;
      }

      &:nth-child(2) {
        display: flex;
      }

      &::after {
        right: -9px;
      }

      & a {
        color: $grey;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        transition: color 0.3s ease;

        &:hover {
          color: $yellow;
        }
      }
    }
  }

  &__right-block {
    display: none;

    @media (min-width: $desktop) {
      overflow: hidden;
      display: flex;
      position: relative;
      min-width: 524px;
      height: 32px;
    }
  }

  &__search-link-mobile {
    @media (max-width: $tablet) {
      display: flex;
      display: none;
      align-items: center;
    }

    @media (min-width: $desktop) {
      display: none;
    }
  }

  &__city {
    display: none;
    transition: transform 0.3s ease;

    @media (min-width: $desktop) {
      display: flex;
      margin-left: auto;
      align-items: center;
    }
  }

  &__city-title {
    @media (min-width: $desktop) {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-right: 8px;
      color: $grey;
    }
  }

  &__city-name {
    @media (min-width: $desktop) {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      color: $grey;
      position: relative;
      padding-right: 26px;
      cursor: pointer;
      margin-right: 49px;
    }

    &::after {
      @media (min-width: $desktop) {
        content: '';
        display: block;
        width: 14px;
        height: 8px;
        position: absolute;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-image: url('../../images/svg/arrow-down.svg');
      }
    }
  }

  &__language {
    display: none;
    transition: transform 0.3s ease;

    @media (min-width: $desktop) {
      // display: flex;
      align-items: center;
      margin-right: 48px;
    }

    & ul {
      @media (min-width: $desktop) {
        display: flex;
      }

      & li {
        @media (min-width: $desktop) {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 17px;
          color: $grey;
          margin-right: 24px;
        }

        &:last-child {
          @media (min-width: $desktop) {
            margin-right: 0;
          }
        }
      }
    }
  }

  &__search {
    transition: transform 0.3s ease;
    display: flex;
    display: none;
    align-items: center;
    position: relative;
    cursor: pointer;

    @media (min-width: $desktop) {
      padding-left: 26px;
    }

    &::after {
      @media (max-width: $tablet) {
        content: none;
      }

      left: 0;
    }

    & img {
      width: 20px;
      height: 20px;

      @media (min-width: $desktop) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 28px;
      }
    }
  }

  &__search-input-wrap {
    position: absolute;
    left: 0;
    z-index: -1;

    &::before {
      display: block;
      content: '';
      width: 24px;
      transform: translateX(100vw);
      height: 24px;
      background-image: url(../../images/svg/icon_search_outline.svg);
      background-size: cover;
      background-position: center;
      position: absolute;
      left: 12px;
      top: 4px;
      z-index: 2;
      transition: transform 0.3s ease;
    }
  }

  &__search-input {
    border: 1px solid #383937;
    border-radius: 3px;
    min-width: 524px;
    box-sizing: border-box;
    padding: 5px 16px 3px 44px;
    height: 32px;
    outline: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: -0.03px;
    transform: translateX(100vw);
    transition: transform 0.3s ease;

    &::placeholder {
      text-indent: 0px;
      transition: text-indent 0.3s ease;
    }

    &:focus {
      outline: none;

      &::placeholder {
        text-indent: 500px;
        transition: text-indent 0.3s ease;
      }
    }
  }
}

.last-seen {
  @media (min-width: $desktop) {
    position: relative;
  }

  &__points {
    color: $white;
    cursor: pointer;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.001em;
  }

  &__pop-up-menu {
    position: absolute;
    width: 248px;
    background-color: $white;
    z-index: 3;
    box-shadow: 0px 1px 4px rgba(66, 66, 66, 0.2);

    @media (max-width: $tablet) {
      top: 45px;
      right: 50%;
      transform: translate(50%);
    }

    @media (min-width: $desktop) {
      top: 37px;
      left: 0;
    }

    & ul {
      display: flex;
      flex-direction: column;

      & li {
        display: flex;
        margin-right: 0 !important;
        border-bottom: 1px solid $grey;

        //&:last-child {
        //  display: none;
        //}

        &:hover {
          background-color: #e6e7e8;
        }

        & a {
          padding: 16px 20px;
          color: $black;
          width: 100%;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.4px;
        }
      }
    }
  }
}
