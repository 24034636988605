@import "styles/variables.scss";

.mobile-nav {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: $black;
    padding: 16px 16px 35px;
    top: 0;
    left: 0;
    z-index: 9999999999;
    overflow: scroll;
    box-sizing: border-box;

    &__wrap {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 4;
    }

    &__navigation {
        margin-left: 11px;
        margin-bottom: 93px;
        position: relative;
        z-index: 4;
    }

    &__first-menu {
        width: 100%;
        transition: transform 0.3s ease;

        & li {
            display: flex;

            &:not(:last-child) {
                margin-bottom: 32px;
            }

            & a {
                font-size: 16px;
                width: 100%;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: -0.001em;
                color: $white;
            }
        }
    }

   &__close {
    position: relative;
    z-index: 5;
    width: 24px;
    height: 24px;
    margin: 0 0 84px auto;
    background-image: url(../../../images/svg/close-cross-white.svg);
   }

   &__languages {
       display: none;
       margin-bottom: 32px;

       & ul {
           & li {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            color: $white;
           }
       }
   }

   &__build {
       display: flex;
       flex-direction: column;
       margin-bottom: 32px;
   }

   &__build-in {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.001em;
        color: $white;
   }

   &__build-in-city {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: $white;
        display: flex;
        align-items: center;

        &::after {
            display: block;
            content: '';
            width: 8px;
            margin-left: 15px;
            height: 14px;
            background-image: url(../../../images/svg/white-arrow-button.svg);
        }
   }

   &__call {
       display: flex;

       &-phone {
        display: flex;
        align-items: center;
        color: $yellow;
        text-decoration: underline;
        margin-right: 30px;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: -0.001em;


           &::before {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            margin-right: 10px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("../../../images/svg/yellow-phone.svg");
           }
       }
   }

   &__second-menu {
       position: absolute;
       top: -40px;
       left: 10px;
       display: flex;
       flex-direction: column;
       transform: translateX(100vw);
       transition: transform 0.3s ease;

       & ul {
        width: 100%;

        & li {
            display: flex;

            &:not(:last-child) {
                margin-bottom: 32px;
            }

            & a {
                font-size: 16px;
                width: 100%;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: -0.001em;
                color: $white;
            }
        }
     }
   }

   &__second-menu-back {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $white;
    margin-bottom: 31px;
    display: flex;
    align-items: center;

    &::before {
        content: '';
        display: block;
        width: 16px;
        height: 10px;
        margin-right: 11px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("../../../images/svg/white-arrow-breadcrumbs.svg");
       }
   }

   &__second-menu-more {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.001em;
    color: $yellow;
    margin-bottom: 32px;
   }
}