@import "styles/variables.scss";

.header {
  background: $black;
  padding: 16px 18px 16px 16px;

  @media (min-width: $desktop) {
    padding: 16px 0;
  }

  &__wrap {
    display: flex;
    align-items: center;
  }

  &__logo {
    margin-right: 80px;
    display: flex;

    & img {
      @media (max-width: $tablet) {
        width: 123px;
        height: 32px;
      }
  
      @media (min-width: $desktop) {
        width: 154px;
        height: 40px;
      }
    }
  }

  &__nav {
    @media (max-width: $tablet) {
      display: none;
    }

    @media (min-width: $desktop) {
      display: block;
      position: relative;
    }

    & ul {
      list-style: none;
      @media (min-width: $desktop) {
        display: flex;
      }
      
      & li {
        @media (min-width: $desktop) {
          cursor: pointer;
          &:not(:last-child) {
            margin-right: 64px;
          }
        }

        & a {
          @media (min-width: $desktop) {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            color: $white;
          }

          &.--active {
            @media (min-width: $desktop) {
              position: relative;
            }

            &::after {
              @media (min-width: $desktop) {
                display: block;
                content: '';
                width: 100%;
                height: 4px;
                background: $yellow;
                position: absolute;
                left: 0;
                bottom: -8px;
              }
            }
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__pop-up-menu {
    position: absolute;
    top: 48px;
    right: -128px;
    width: 248px;
    background-color: $white;
    z-index: 2;
    box-shadow: 0px 1px 4px rgba(66, 66, 66, 0.2);

    & ul {
      display: flex;
      flex-direction: column;

      & li {
        display: flex;
        margin-right: 0 !important;
        border-bottom: 1px solid $grey;

        &:hover {
          background-color: #E6E7E8;
        }

        & a {
          padding: 16px 20px;
          color: $black;
          width: 100%;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.4px;

          
        }
      }
    }
  }

  &__contact {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  &__contact-button {
    display: none;

    @media (min-width: $desktop) {
      display: block;
    }
  }

  &__filter-button {
    &::after {
      @media (max-width: $tablet) {
        content: '';
        display: block;
        width: 18px;
        height: 14px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("../../images/svg/mobile-burger-menu.svg");
      }
    }
  }

  &__contact-link {
    display: flex;
    align-items: center;

    @media (max-width: $tablet) {
      margin-right: 21px;
    }

    &::before {
      @media (max-width: $tablet) {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("../../images/svg/Icon_call_outline.svg");
      }

      @media (min-width: $desktop) {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("../../images/svg/yellow-phone.svg");
      }
    }

    & span {
      @media (max-width: $tablet) {
        display: none;
      }

      @media (min-width: $desktop) {
        margin-right: 20px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: $yellow;
        display: flex;
        align-items: center;
      }
    }
  }
}